/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import './App.css';
import { useInView } from 'react-intersection-observer';

import BannerSection from './sections/Banner';
import WhatSection from './sections/What';
import WhySection from './sections/Why';
import HowSection from './sections/How';
import FooterSection from './sections/Footer';
import Popup from './components/Popup';

const App = () => {
  const [activeLink, setActiveLink] = useState('#home');
  const [delayedActiveLink, setDelayedActiveLink] = useState('#home');

  const [bannerRef, bannerInView] = useInView({ threshold: 0.5 });
  const [whatRef, whatInView] = useInView({ threshold: 0.5 });
  const [howRef, howInView] = useInView({ threshold: 0.5 });
  const [whyRef, whyInView] = useInView({ threshold: 0.5 });
  const [demoRef, demoInView] = useInView({ threshold: 0.5 });
  const [isPopupOpen, setPopupOpen] = useState(false);

  const handleDemoClick = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (bannerInView) setDelayedActiveLink('#home');
      else if (whatInView) setDelayedActiveLink('#what');
      else if (howInView) setDelayedActiveLink('#how');
      else if (whyInView) setDelayedActiveLink('#why');
      else if (demoInView) setDelayedActiveLink('#demo');
    }, 100);

    return () => clearTimeout(timer);
  }, [bannerInView, whatInView, howInView, whyInView, demoInView]);

  useEffect(() => {
    setActiveLink(delayedActiveLink);
  }, [delayedActiveLink]);

  return (
    <>
      <header className='fixed top-0 left-0 w-full z-10 flex justify-center'>
        <a className={`text-white font-md font-bold p-4 ${activeLink === '#home' && 'active'}`} href="#home">Home</a>
        <a className={`text-white font-md font-bold p-4 ${activeLink === '#what' && 'active'}`} href="#what">What</a>
        <a className={`text-white font-md font-bold p-4 ${activeLink === '#how' && 'active'}`} href="#how">How</a>
        <a className={`text-white font-md font-bold p-4 ${activeLink === '#why' && 'active'}`} href="#why">Why</a>
        <a className="text-white font-md font-bold p-4" href="#" onClick={handleDemoClick}>
          Demo
        </a>
        <Popup isOpen={isPopupOpen} onClose={handleClosePopup}>
          <a href="https://www.nohaz.co/en/contact-us" target='_blank' rel="noreferrer" className='font-md'>Request a demo</a>
        </Popup>
      </header>
      <div className="snap-y snap-mandatory overflow-y-scroll h-screen flex-grow z-0 bg-[#181919] scroll-smooth bg-primary-pattern">
        <div className='snap-always snap-center' ref={bannerRef} id="home">
          <BannerSection />
        </div>
        <div className='snap-always snap-center' ref={whatRef} id="what">
          <WhatSection />
        </div>
        <div className='snap-always snap-center' ref={howRef} id="how">
          <HowSection />
        </div>
        <div className='snap-always snap-center' ref={whyRef} id="why">
          <WhySection />
        </div>
        <div className='snap-always snap-center' ref={demoRef} id="demo">
          <FooterSection />
        </div>
      </div>
    </>
  );
}

export default App;
