import Background from '../assets/images/slide1.png';
import Logo from '../assets/images/logo.png';

const Banner = () => {
  return (
    <div className="relative h-screen flex items-center" id="home">
      <img
        className="absolute inset-0 w-full h-full object-cover"
        src={Background}
        alt="Background"
      />

      <div className="absolute inset-0 bg-black opacity-50"></div>

      <div className="absolute p-8 text-white sm:top-0 sm:left-0">
        <h1 className="text-4xl font-bold">TEXTMOGRIFY™</h1>
        <p>Discover, Organize, and Share Knowledge</p>
      </div>

      <div className="absolute bottom-0 left-0 p-8 text-white">
        <img src={Logo} alt="" width={200} />
      </div>
    </div>
  );
};

export default Banner;