import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ProposeSVG from "../assets/svg/what/propose";
import QuerySVG from "../assets/svg/what/query";
import RedactSVG from "../assets/svg/what/redact";
import VisualizeSVG from "../assets/svg/what/visualize";
import { useInView } from "react-intersection-observer";

const What = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-100px 0px',
  });

  return (
    <div className="h-screen bg-cover overflow-hidden">
      <div className="hidden md:block h-screen">
        <div ref={ref} className="max-w-[1200px] h-full w-full mx-auto flex items-center justify-around">
          <QuerySVG animate={inView} />
          <RedactSVG animate={inView} />
          <ProposeSVG animate={inView} />
          <VisualizeSVG animate={inView} />
        </div>
      </div>

      <div className="md:hidden flex justify-center items-center h-screen">
        <Carousel
          infiniteLoop={true}
          autoPlay={true}
          interval={5000}
          className="w-full"
        >
          <div className="flex justify-center">
            <QuerySVG animate={true} />
          </div>
          <div className="flex justify-center">
            <RedactSVG animate={true} />
          </div>
          <div className="flex justify-center">
            <ProposeSVG animate={true} />
          </div>
          <div className="flex justify-center">
            <VisualizeSVG animate={true} />
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default What;
