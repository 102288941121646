import { useInView } from "react-intersection-observer";
import HowSVG from "../assets/svg/how/how";

const How = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-100px 0px',
  });

  return (
    <div className="h-screen bg-cover">
      <div ref={ref} className="max-w-[1200px] h-full w-full mx-auto flex items-center justify-around">
        <HowSVG animate={inView} />
      </div>
    </div>
  );
};

export default How;