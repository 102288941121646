import { useInView } from "react-intersection-observer";
import WhySvg from "../assets/svg/why/why";

const Why = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-100px 0px',
  });

  return (
    <div className="h-screen bg-cover overflow-hidden">
      <div ref={ref} className="h-full w-full mx-auto flex items-center justify-around">
        <WhySvg animate={inView} />
      </div>
    </div>
  );
};

export default Why;